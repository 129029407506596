import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Landing from './pages/Landing';
import Register from './pages/Register';
import Started from './pages/Started';
import Done from './pages/Done';
import ErrorPage from './pages/ErrorPage';
import backgroundImg from './assets/image/background.png';
import { CircularProgress, useTheme } from '@mui/material';
import {
  getAppIdPayload,
  getAppInfo,
  isShareParams,
  startMobileExperience,
  StartMobileExperiencePayload
} from './api';
import { AxiosResponse } from 'axios';
import { CookieModal } from './components/CookieModal';
import ReactGA from 'react-ga4';
import {
  hashEmailStringWithSHA256,
  hashStringWithSHA256,
  isTesterEmail,
  isTesterPhone
} from './gtag/utils';
import { GAEvents } from './gtag/constants';
import useSearchParams, { SearchParamHashType } from './hooks/useSearchParams';
import NewLinkSent from './pages/NewLinkSent';
import Typography from '@mui/material/Typography';

export type SetUserDataValue = (prev: UserData) => UserData;

const isPageType = (state: Pages) => {
  if (
    [
      Pages.Landing,
      Pages.Register,
      Pages.Started,
      Pages.Done,
      Pages.Error,
      Pages.NewLinkSent
    ].includes(state)
  )
    return true;
  return false;
};

export interface PageProps {
  setPage: (value: Pages) => void;
  userData: UserData;
  startExperience: (
    fromRegister: boolean
  ) => Promise<Error | AxiosResponse<null, any> | undefined>;
}

export interface RouterProps {
  state: Pages;
  appIdErrorMessage: string;
}

export type UserData = {
  email: string;
  phone: string;
  needs_scan: boolean;
  needs_share: boolean;
  needs_register: boolean;
  share_privacy_url: string;
  share_app_name: string;
};

export const searchParamHash: SearchParamHashType = {
  appid: 'appId'
};

export const initialUserData = {
  email: '',
  phone: '',
  needs_register: false,
  needs_scan: false,
  needs_share: false,
  share_privacy_url: '',
  share_app_name: ''
};

export const unknownShareAppName = 'Unknown';

export enum Pages {
  Landing = 'landing',
  Started = 'started',
  Register = 'register',
  Done = 'done',
  Error = 'error',
  NewLinkSent = 'newlinksent'
}

const Router: FC<RouterProps> = ({ state, appIdErrorMessage }) => {
  const searchParams = useSearchParams(searchParamHash);

  const [currentPage, _setPage] = useState<Pages>(
    isPageType(state) ? state : Pages.Landing
  );
  const [userData, setUserData] = useState(initialUserData);
  const [serverErrorMessage, setServerErrorMessage] = useState(
    appIdErrorMessage ?? ''
  );

  const setPage = (page: Pages) => {
    if (page === Pages.Landing) setUserData(initialUserData);
    return _setPage(page);
  };

  const startExperience = async (fromRegister: boolean) => {
    // a place holder to show this function is available
    const payloada: getAppIdPayload = { appid: '' };
    payloada.appid = '535f0f02-08cf-488e-a148-f3c0892244de';
    const response_info: any = await getAppInfo(payloada);
    console.log('sending appid');
    console.log(response_info);
    console.log('check');

    const payload: StartMobileExperiencePayload = {};
    payload.origin_url = window.location.origin;
    if (userData.phone) {
      payload.phone = `+${userData.phone}`;
      ReactGA.event(GAEvents.SignInPhone, {
        phone: hashStringWithSHA256(userData.phone)
      });
      if (isTesterPhone(userData.phone)) {
        ReactGA.set({
          tester: true
        });
      }
    } else if (userData.email) {
      payload.email = userData.email;
      ReactGA.event(GAEvents.SignInEmail, {
        email: hashEmailStringWithSHA256(userData.email)
      });
      if (isTesterEmail(userData.email)) {
        ReactGA.set({
          tester: true
        });
      }
    } else {
      throw new Error("Can't start experience without contact info!");
    }

    if (isShareParams(searchParams)) {
      payload.shareParams = searchParams;

      ReactGA.event(GAEvents.WithCompanyInfo, {
        name: hashStringWithSHA256(searchParams.name),
        companyID: hashStringWithSHA256(searchParams.appId)
      });
    } else {
      ReactGA.event(GAEvents.WithoutCompanyInfo);
    }

    if (
      searchParams?.appId &&
      !/[a-f\d]{8}-[a-f\d]{4}-4[a-f\d]{3}-[89ab][a-f\d]{3}-[a-f\d]{12}/.test(
        searchParams?.appId
      )
    ) {
      setPage(Pages.Error);
      return;
    }

    // if coming from register page, then the user has agreed to share by checking the checkbox
    if (payload.shareParams)
      payload.shareParams.share_checkbox_checked = fromRegister;

    return await startMobileExperience(payload);
  };

  const getCurrentPage = () => {
    switch (currentPage) {
      case Pages.Landing:
        return (
          <Landing
            setPage={setPage}
            userData={userData}
            setUserData={setUserData}
            startExperience={startExperience}
            setServerErrorMessage={setServerErrorMessage}
          />
        );
      case Pages.Register:
        return (
          <Register
            setPage={setPage}
            userData={userData}
            startExperience={startExperience}
          />
        );
      case Pages.Started:
        return (
          <Started
            setPage={setPage}
            userData={userData}
            startExperience={startExperience}
          />
        );
      case Pages.Done:
        return (
          <Done
            setPage={setPage}
            userData={userData}
            startExperience={startExperience}
          />
        );
      case Pages.Error:
        return (
          <ErrorPage
            serverErrorMessage={serverErrorMessage && serverErrorMessage}
          />
        );
      case Pages.NewLinkSent:
        return <NewLinkSent />;
      default:
        return null;
    }
  };

  return getCurrentPage();
};

export type StateParams = {
  state: string;
};

export function isStateParams(
  params: Record<string, unknown>
): params is StateParams {
  return 'state' in params;
}

const App = () => {
  const theme = useTheme();
  const searchParams = useSearchParams(searchParamHash);

  // set page state from incoming state query parameter if provided
  const [pageState, setPageState] = useState('');
  const [isCheckAppIdLoading, setIsCheckAppIdLoading] = useState(false);
  const [appData, setAppData] = useState(null);
  const [appIdErrorMessage, setAppIdErrorMessage] = useState('');

  const checkAppId = async (payload: getAppIdPayload) => {
    setIsCheckAppIdLoading(true);
    const response: any = await getAppInfo(payload);
    setIsCheckAppIdLoading(false);
    if (response?.status === 404 && response?.data?.detail) {
      setAppIdErrorMessage(response.data.detail);
      setPageState(Pages.Error);
    }
    if (response?.status === 200 && response?.data) {
      setAppData(response.data);
    }
  };

  useEffect(() => {
    if (isStateParams(searchParams)) {
      setPageState(searchParams['state']);
    }
    if (searchParams?.appId) {
      checkAppId({ appid: searchParams.appId });
    }
  }, [searchParams]);

  return (
    <Box>
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            {!isCheckAppIdLoading && appData && (
              <Typography variant={'h6'}>
                {(appData?.['app_name'] ? appData?.['app_name'] : 'Someone') +
                  ' '}
                invited you to share your measures.
              </Typography>
            )}
            {isCheckAppIdLoading ? (
              <CircularProgress />
            ) : (
              <Router
                key={pageState}
                state={pageState as Pages}
                appIdErrorMessage={appIdErrorMessage}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <Box
            sx={{
              position: 'relative',
              img: {
                width: '100%',
                height: '100vh',
                objectFit: 'cover'
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'inline-block',
                width: '100%',
                height: '100%',
                opacity: '0.6',
                background: '#fff'
              }
            }}
          >
            <img src={backgroundImg} />
          </Box>
        </Grid>
        <CookieModal />
      </Grid>
    </Box>
  );
};

export default App;
