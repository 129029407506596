import { useState, useEffect } from 'react';

export interface SearchParamHashType {
  [key: string]: string;
}
interface SearchParamsType {
  [key: string]: string;
}

function useSearchParams(
  searchParamHash: SearchParamHashType
): SearchParamsType {
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    const alteredSearchParams = Array.from(
      new URLSearchParams(location.search).entries()
    ).map(([key, value]) => {
      const lowerCasedKey = key.toLowerCase();
      return [searchParamHash[lowerCasedKey] || key, value];
    });

    const paramsObject = Object.fromEntries(alteredSearchParams);
    setSearchParams(paramsObject);
  }, [searchParamHash]);

  return searchParams;
}

export default useSearchParams;
